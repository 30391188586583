import { admin } from "./admin";
import { user } from "./user";
// import { supervisor } from "./supervisor";

//-----------------------|| MENU ITEMS ||-----------------------//
const showNav = JSON.parse(localStorage.getItem("userData"));

const menuItems = {
  items: [
   showNav?.role === "admin"
      ? admin
      : user,
  ],
};

export default menuItems;
