import React, { lazy } from "react";
import { Route, Switch, useLocation } from "react-router-dom";

import AuthGuard from "./../utils/route-guard/AuthGuard";
import Loadable from "../ui-component/Loadable";
// project imports
import MainLayout from "./../layout/MainLayout";

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import("../views/dashboard/Default"))
);

// utilities routing
const UtilsTypography = Loadable(lazy(() => import("../views/categories/")));
const UtilsColor = Loadable(lazy(() => import("../views/utilities/Color")));
const UtilsShadow = Loadable(lazy(() => import("../views/utilities/Shadow")));
const UtilsMaterialIcons = Loadable(
  lazy(() => import("../views/utilities/MaterialIcons"))
);
const UtilsTablerIcons = Loadable(
  lazy(() => import("../views/utilities/TablerIcons"))
);
const CreateNewProduct = Loadable(
  lazy(() => import("../views/categories/Newcategory"))
);

// sample page routing
const SamplePage = Loadable(lazy(() => import("../views/sample-page")));
const Profile = Loadable(lazy(() => import("../views/profile")));
const Certificates = Loadable(lazy(() => import("../views/certificates")));
const NewCertificate = Loadable(
  lazy(() => import("../views/certificates/newCertificate"))
);
const ManageMasteries = Loadable(
  lazy(() => import("../views/certificates/manageMasteries"))
);
const Dashboard = Loadable(lazy(() => import("../views/user-dashboard")));
const Admin = Loadable(lazy(() => import("../views/admin")));
const UserList = Loadable(lazy(() => import("../views/user-lists")));
const UserEdit = Loadable(lazy(() => import("../views/user-list-edit")));
const UserStatusReport = Loadable(
  lazy(() => import("../views/user-dashboard/status-report"))
);
const CreateCertificate = Loadable(
  lazy(() => import("../views/certificates/createCertificate"))
);
const UploadCertificateImage = Loadable(
  lazy(() => import("../views/certificates/imageUpload"))
);
const EditCertificate = Loadable(
  lazy(() => import("../views/certificates/editCertificate"))
);
const AssignCourse = Loadable(lazy(() => import("../views/assign-course")));
const UserEditCourse = Loadable(
  lazy(() => import("../views/user-dashboard/user-course-selection"))
);
const AdminEditCourse = Loadable(
  lazy(() => import("../views/admin-course-selection"))
);
const Company = Loadable(lazy(() => import("../views/company")));
const NewCompany = Loadable(lazy(() => import("../views/company/newCompany")));
const EditCompany = Loadable(
  lazy(() => import("../views/company/editCompany"))
);
const SupervisorUser = Loadable(lazy(() => import("../views/supervisor")));
const NewSupervisorUser = Loadable(
  lazy(() => import("../views/supervisor/newSupervisor"))
);
const SupervisorAddCourse = Loadable(
  lazy(() => import("../views/supervisor/addSupervisorCourse"))
);
const SupervisorUserStatueReport = Loadable(
  lazy(() => import("../views/supervisor/supervisorsStatusReport"))
);
const SupervisorUserTeamStatueReport = Loadable(
  lazy(() => import("../views/supervisor/teamStatus"))
);
const AdminUserStatusReport = Loadable(
  lazy(() => import("../views/admin/user-status-report"))
);

//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
  const location = useLocation();

  return (
    <Route
      path={[
        "/dashboard/default",

        "/utils/util-typography",
        "/utils/util-color",
        "/utils/util-shadow",
        "/icons/tabler-icons",
        "/icons/material-icons",
        "/category/createnew",
        "/sample-page",
        "/profile",
        "/certificates",
        "/dashboard",
        "/supervisor",
        "/supervisor-user",
        "/mastery",
        "/admin",
        "/edit-company/:id",
        "/assign-user",
        "/user-list",
        "/user-edit/:id",
        "/user-status-report",
        "/unassigned-users",
        "/assign-course",
        "/user-course-edit",
        "/admin-course-edit/:id",
        "/company",
        "/new-company",
        "/new-supervisor-user",
        "/supervisor-add-course/:id",
        "/status-report/:id",
        "/supervisor-team-report",
        "/new-certificate",
        "/create-certificate",
        "/edit-certificate",
        "/upload-image",
        "/manage-masteries/:id",
        "/admin-user-report/:id",
      ]}
    >
      <MainLayout>
        <Switch location={location} key={location.pathname}>
          <AuthGuard>
            <Route path="/dashboard/default" component={DashboardDefault} />
            <Route path="/utils/util-typography" component={UtilsTypography} />
            <Route path="/utils/util-color" component={UtilsColor} />
            <Route path="/utils/util-shadow" component={UtilsShadow} />
            <Route path="/icons/tabler-icons" component={UtilsTablerIcons} />
            <Route
              path="/icons/material-icons"
              component={UtilsMaterialIcons}
            />
            <Route path="/category/createnew" component={CreateNewProduct} />
            <Route path="/sample-page" component={SamplePage} />
            <Route path="/profile" component={Profile} />
            <Route path="/certificates" component={Certificates} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/admin" component={Admin} />
            <Route path="/user-list" component={UserList} />
            <Route path="/user-edit/:id" component={UserEdit} />
            <Route path="/user-status-report" component={UserStatusReport} />
            <Route path="/assign-course" component={AssignCourse} />
            <Route path="/user-course-edit" component={UserEditCourse} />
            <Route path="/admin-course-edit/:id" component={AdminEditCourse} />
            <Route path="/company" component={Company} />
            <Route path="/new-company" component={NewCompany} />
            <Route path="/edit-company/:id" component={EditCompany} />
            <Route path="/supervisor-user" component={SupervisorUser} />
            <Route path="/new-supervisor-user" component={NewSupervisorUser} />
            <Route
              path="/supervisor-add-course/:id"
              component={SupervisorAddCourse}
            />
            <Route
              path="/status-report/:id"
              component={SupervisorUserStatueReport}
            />
            <Route
              path="/supervisor-team-report"
              component={SupervisorUserTeamStatueReport}
            />
            <Route path="/new-certificate" component={NewCertificate} />
            <Route path="/create-certificate" component={CreateCertificate} />
            <Route path="/edit-certificate/:id" component={EditCertificate} />
            <Route path="/upload-image" component={UploadCertificateImage} />
            <Route path="/manage-masteries/:id" component={ManageMasteries} />
            <Route
              path="/admin-user-report/:id"
              component={AdminUserStatusReport}
            />
          </AuthGuard>
        </Switch>
      </MainLayout>
    </Route>
  );
};

export default MainRoutes;
