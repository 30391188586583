// assets
import {
  IconBrandChrome,
  IconHelp,
  IconSitemap,
  IconUserPlus,
  IconUserCheck,
  IconUserExclamation,
  IconCertificate,
} from "@tabler/icons";

// constant
const icons = {
  IconBrandChrome: IconBrandChrome,
  IconHelp: IconHelp,
  IconSitemap: IconSitemap,
  IconUserPlus: IconUserPlus,
  IconCertificate: IconCertificate,
  IconUserExclamation: IconUserExclamation,
  IconUserCheck: IconUserCheck,
};

//-----------------------|| UTILITIES MENU ITEMS ||-----------------------//

export const user = {
  id: "user",
  title: "Main",
  type: "group",
  children: [
    {
      id: "user-dashboard",
      title: "User Dashboard",
      type: "item",
      url: "/dashboard",
      icon: icons["IconSitemap"],
      breadcrumbs: false,
    },
    {
      id: "profile",
      title: "Profile",
      type: "item",
      url: "/profile",
      icon: icons["IconUserPlus"],
      breadcrumbs: false,
    },
    {
      id: "certificates",
      title: "Certificates",
      type: "item",
      url: "/certificates",
      icon: icons["IconCertificate"],
      breadcrumbs: false,
    },
  ],
};
