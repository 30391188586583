const config = {
  basename: "/",
  defaultPath: "/dashboard",
  fontFamily: `'Manrope', sans-serif`,
  borderRadius: 12,
  // URL: "http://localhost:5002/v1",
  URL: "https://api.skillzup.app/v1",
};

export const USER_INFO = JSON.parse(localStorage.getItem("userData"));
export const SESSION_TOKEN = localStorage.getItem("sessionToken");
export const EMAIL = localStorage.getItem("email");
export const TOKEN = localStorage.getItem("token");
export const COURSES_API = `${config.URL}/courses`;
export const TIMEZONES_API = `${config.URL}/timezones`;
export const ENGAGEMENT_API = `${config.URL}/engagements`;
export const SIGN_UP_API = `${config.URL}/users/signup`;
export const LOGIN_API = `${config.URL}/users/signin`;
export const USERS = `${config.URL}/users`;
export const COMPANY = `${config.URL}/companies`;
export const NEW_COMPANY = `${config.URL}/companies/create`;
export const MASTERY_PROFILES = `${config.URL}/mastery-profiles`;
export const USER_STATUS_VIEW = `${config.URL}/users/progressReport`;
export const USER_DATA = `${config.URL}/users/emailAddress?emailAddress`;
export const USER_COURSES = `${config.URL}/user-courses`;
export const SUPERVISOR_USERS = `${config.URL}/companies/supervisor`;
export const SUPERVISOR_CREATE_USERS = `${config.URL}/users/companySupervisor/createUser`;
export const SUPERVISOR_ADD_COURSE = `${config.URL}/user-courses/assignCoursesToUserBySupervisor`;
export const SUPERVISOR_VIEW_COURSE = `${config.URL}/user-courses/findUserCoursesBySupervisor`;
export const SUPERVISOR_EDIT_PREFERENCE = `${config.URL}/users/supervisor/changeEngagementAndTimezone?userId`;
export const SUPERVISOR_PASSWORD_RESET = `${config.URL}/users/supervisor/passwordReset`;
export const SUPERVISOR_REMOVE_COURSE = `${config.URL}/user-courses/unassignCoursesBySupervisor`;
export const SUPERVISOR_USER_STATUS_VIEW = `${config.URL}/companies/users/progressReport`;
export const SUPERVISOR_TEAM_STATUS = `${config.URL}/companies/team/statusReport`;
export const SUPERVISOR_USERS_ENGAGEMENT = `${config.URL}/companies/team/team7daysNotEngaged`;
export const IMAGE_UPLOAD = `${config.URL}/image-uploads`;
export const CREATE_CERTIFICATE = `${config.URL}/certificates/createCertificate`;
export const ALL_CERTIFICATE = `${config.URL}/certificates`;
export const CERTIFICATE_MASTERIES = `${config.URL}/certificate-masteries`;
export const PUBLIC_CERTIFICATE = `${config.URL}/certificates/publicCertificates`;
export const EARNED_CERTIFICATE = `${config.URL}/certificates-earned/allUsersCertificatesEarned`;
export const SUPERVISOR_USERS_EARNED_CERTIFICATES = `${config.URL}/certificates-earned/companyUsersCertificatesEarned`;

export default config;
