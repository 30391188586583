import { useState, useEffect } from "react";
import axios from "axios";

const useAxiosFetch = (dataUrl, tokenName) => {
  const [data, setData] = useState([]);
  const [fetchError, setFetchError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async (url, tokenName) => {
    setIsLoading(true);
    try {
      const session =
        localStorage.getItem('sessionToken') || 'e6d36813cd6664073163f21a22ab24106b56f0d517ca2ca37e33918c74a162be';
      const token = localStorage.getItem(tokenName);
      const response = await axios.get(url, {
        headers: {
          Authorization:
            tokenName === "sessionToken"
              ? `${session}`
              : tokenName === "token"
              ? `Bearer ${token}`
              : session,
        },
      });
      setData(response.data);
      setFetchError(null);
    } catch (err) {
      setFetchError(err?.response?.data?.message);
      setData([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(dataUrl, tokenName);
  }, [dataUrl, tokenName]);

  const refetch = () => {
    fetchData(dataUrl, tokenName);
  };

  return { data, fetchError, isLoading, refetch };
};

export default useAxiosFetch;





