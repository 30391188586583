// assets
import {
  IconDashboard,
  IconDeviceAnalytics,
  IconBrandChrome,
  IconHelp,
  IconSitemap,
  IconUserPlus,
  IconUserCheck,
  IconUserExclamation,
  IconCertificate,
  IconUser,
  IconDoor,
} from "@tabler/icons";

// constant
const icons = {
  IconDashboard: IconDashboard,
  IconDeviceAnalytics,
  IconBrandChrome: IconBrandChrome,
  IconHelp: IconHelp,
  IconSitemap: IconSitemap,
  IconUserPlus: IconUserPlus,
  IconCertificate: IconCertificate,
  IconUserExclamation: IconUserExclamation,
  IconUserCheck: IconUserCheck,
  IconUser: IconUser,
  IconDoor: IconDoor,
};

//-----------------------|| DASHBOARD MENU ITEMS ||-----------------------//

export const admin = {
  id: "admin",
  title: "Dashboard",
  type: "group",
  children: [
    {
      id: "user-dashboard",
      title: "User Dashboard",
      type: "item",
      url: "/dashboard",
      icon: icons["IconSitemap"],
      breadcrumbs: false,
    },
    {
      id: "profile",
      title: "Profile",
      type: "item",
      url: "/profile",
      icon: icons["IconUserPlus"],
      breadcrumbs: false,
    },
    {
      id: "certificates",
      title: "Certificates",
      type: "item",
      url: "/certificates",
      icon: icons["IconCertificate"],
      breadcrumbs: false,
    },
    {
      id: "admin",
      title: "Admin Control",
      type: "item",
      url: "/admin",
      icon: icons["IconUser"],
      breadcrumbs: false,
    },
    {
      id: "company",
      title: "Company Record",
      type: "item",
      url: "/company",
      icon: icons["IconDoor"],
      breadcrumbs: false,
    },
  ],
};
