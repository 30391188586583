import React from "react";

// material-ui
import { Typography } from "@material-ui/core";

// project imports
import NavGroup from "./NavGroup";

import { admin } from "./../../../../menu-items/admin";
import { user } from "./../../../../menu-items/user";
import { supervisor } from "../../../../menu-items/supervisor";

import useAxiosFetch from "../../../../hooks/useFetch";
import  config  from "../../../../config";

//-----------------------|| SIDEBAR MENU LIST ||-----------------------//

const MenuList = () => {
  const emailValue = localStorage.getItem("email");

  const { data: userData } = useAxiosFetch(
    `${config.URL}/users/emailAddress?emailAddress=${emailValue}`,
    "token"
  );

  const menuItem = {
    items: [
      userData?.role === "admin"
        ? admin
        : userData?.role === "supervisor"
        ? supervisor
        : user,
    ],
  };
  const navItems = menuItem.items.map((item) => {
    switch (item.type) {
      case "group":
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return navItems;
};

export default MenuList;
